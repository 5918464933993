// extracted by mini-css-extract-plugin
export var root = "PlasmicFeatureCard-module--root--pXa-D";
export var root__long = "PlasmicFeatureCard-module--root__long--Z3-XW";
export var box__qd98 = "PlasmicFeatureCard-module--box__qd98--2952p";
export var box__lQvXm = "PlasmicFeatureCard-module--box__lQvXm--3P-aA";
export var svg__b9Fyy = "PlasmicFeatureCard-module--svg__b9Fyy--2ukG-";
export var box__ltb4 = "PlasmicFeatureCard-module--box__ltb4--YYOIh";
export var box__long__ltb4XiqIa = "PlasmicFeatureCard-module--box__long__ltb4XiqIa--35xty";
export var slotTitle = "PlasmicFeatureCard-module--slotTitle--ikjab";
export var slotDescription = "PlasmicFeatureCard-module--slotDescription--qbOZz";
export var slotDescription__long = "PlasmicFeatureCard-module--slotDescription__long--2x1ry";
export var box__jhD4 = "PlasmicFeatureCard-module--box__jhD4--z5UP4";
export var box__long__jhD4XiqIa = "PlasmicFeatureCard-module--box__long__jhD4XiqIa--1Fw7r";
export var svg__opjur = "PlasmicFeatureCard-module--svg__opjur--1lL-F";