// extracted by mini-css-extract-plugin
export var root = "PlasmicTopSection-module--root--8bAT5";
export var box___8Fxpi = "PlasmicTopSection-module--box___8Fxpi--2nvag";
export var box__krCvm = "PlasmicTopSection-module--box__krCvm--kgSuR";
export var box__dLmA = "PlasmicTopSection-module--box__dLmA--1dAa0";
export var box__soAji = "PlasmicTopSection-module--box__soAji--1rH1x";
export var box__rl4KB = "PlasmicTopSection-module--box__rl4KB--3mUVu";
export var box__frPlP = "PlasmicTopSection-module--box__frPlP--25HZg";
export var box__aS229 = "PlasmicTopSection-module--box__aS229--2eSA-";
export var linkButton__gHmwe = "PlasmicTopSection-module--linkButton__gHmwe--BIYDZ";
export var linkButton__mDgRz = "PlasmicTopSection-module--linkButton__mDgRz--1sNIQ";
export var box__lPtB = "PlasmicTopSection-module--box__lPtB--3Bp8e";
export var img = "PlasmicTopSection-module--img--22FGd";